<script>
    import Link from '@itslearning/prometheus/assets/Navigation/Link/v1/Link.svelte';

    export let i18n = {};
    export let globalSiteUrl = undefined;
</script>

<section class="promotion | narrow-content">
    <h2 class="promotion_header | text-400">{ i18n.welcome_promotion_header }</h2>

    <p class="promotion_text | text-200">{ i18n.welcome_promotion_text }</p>

    <Link kind="standalone" href="{ globalSiteUrl }" target="_blank" iconClass="arrow-right-solid" iconPlacement="end">{ i18n.welcome_promotion_link_text }</Link>
</section>
<div class="language-picker">
    <button
        id="language-picker--modal-button"
        class="language-picker--modal-button"
        type="button"
        data-value={selectedLanguage.value}
        on:click={showModal}
        aria-haspopup="true"
    >
        <span class="language-picker--modal-button-text">
            <p
                class="language-picker--modal-button-text-part">
                {selectedLanguage.text}
            </p>
            <p class="screen-reader">
                {i18n.changeLanguage}
            </p>
        </span>
    </button>

    {#if $languagePickerStore.showModal}
        <Modal
            confirmButtonType="secondary"
            confirmText={i18n.close}
            titleText={i18n.selectLanguage}
            showCancel={false}
            bind:enabled={modalEnabled}
        >
            <div slot="body">
                <GridSelect
                    id="language-picker--grid-select"
                    label={i18n.selectLanguage}
                    labelHidden={true}
                    itemsPerColumn={7}
                    selectedValue={$languagePickerStore.selectedLanguageValue}
                    items={languagePickerItems}
                    large={true}
                    on:select={selectLanguage} />
            </div>
        </Modal>
    {/if}
</div>

<script>
    import Modal from '@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte';
    import GridSelect from '@itslearning/prometheus/assets/inputs/GridSelect/v1/GridSelect.svelte';

    import { createEventDispatcher } from 'svelte';
    import { languagePickerStore } from '../../stores.js';

    const dispatch = createEventDispatcher();

    export let i18n = {};
    export let languagePickerLanguages = [];

    let modalEnabled = false;

    let selectedLanguage ;

    $: languagePickerItems = languagePickerLanguages && languagePickerLanguages.length > 0
        ? languagePickerLanguages.map(item => ({ ...item, lang: item.value }))
        : [];

    function showModal() {
        modalEnabled = true;
        dispatch('showModal');
    }

    function hideModal() {
        modalEnabled = false;
        dispatch('hideModal');
    }

    function selectLanguage(event) {
        hideModal();

        const value = event.detail.data;

        if ($languagePickerStore.selectedLanguageValue !== value) {
            const selectedItem = languagePickerItems.find(o => o.value === value);

            dispatch('select', { selectedItem });
        }
    }

    $: {
        let selected = languagePickerItems.find(o => o.value === $languagePickerStore.selectedLanguageValue);

        if (selected === undefined && languagePickerItems.length > 0) {
            selected = languagePickerItems[0];
        }

        selectedLanguage = selected;
    }
</script>

import mountReplace from '@itslearning/prometheus/lib/helpers/mountReplace';

import LanguagePicker from './Views/LanguagePicker/LanguagePicker.svelte';
import Banner from './Views/Banner/Banner.svelte';
import LoginDisabled from './Views/LoginDisabled/LoginDisabled.svelte';
import Login from './Views/Login/Login.svelte';
import Welcome from './Views/Welcome/Welcome.svelte';

import languageController from './Controllers/languageController.js';
import config from './config.js';
import { bannerStore } from './stores.js';

const languagePicker = mountReplace(LanguagePicker, {
    props: config,
    target: document.getElementById('language-picker')
});

languagePicker.$on('select', event => languageController.languageSelected(event));
languagePicker.$on(
    'showModal',
    () => languageController.showModal());
languagePicker.$on('hideModal', () => languageController.hideModal());

const bannerHook = document.getElementById('login-banner');

if (bannerHook) {
    mountReplace(Banner, {
        props: config,
        target: bannerHook
    });

    // Set banner visible after a timeout so that the live region gets rendered without a
    // banner in the first render, then updated to add the banner so that it is read aloud
    setTimeout(() => {
        const loginBannerLocalizedText = config.loginBannerLocalizedText;

        if (loginBannerLocalizedText) {
            bannerStore.set({
                bannerVisible: true,
                bannerMessage: loginBannerLocalizedText
            });
        }
    }, 0);
}

const loginDisabledHook = document.getElementById('login-disabled');

if (loginDisabledHook) {
    mountReplace(LoginDisabled, {
        props: config,
        target: loginDisabledHook
    });
}

const loginHook = document.getElementById('modern-login');

if (loginHook) {
    mountReplace(Login, {
        target: loginHook,
        props: config
    });
}

const welcomeSection = document.getElementById('promotion');

if (welcomeSection){
    mountReplace(Welcome, {
        props: config,
        target: welcomeSection
    });
}
<div class="login-disabled">
    <Banner
        type="warning"
        visible={true}
        text={i18n.loginDisabled}
        closable={false}
        close={i18n.close} />
</div>

<script>
    import Banner from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';

    export let i18n = {};
</script>

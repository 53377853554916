import { writable } from 'svelte/store';
import config from './config.js';

export const bannerStore = writable({
    bannerVisible: false,
    bannerMessage: ''
});

export const languagePickerStore = writable({
    showModal: false,
    selectedLanguageValue: config.languagePickerSelectedValue
});

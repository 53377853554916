<Banner
    type="warning"
    text={loginErrorText}
    closable={false}
    visible={loginErrorText !== ""}
/>
<div class="itsl-itsl-native-login-view__username" bind:this={userNameContainer}>
    <TextField
        id="UsernameField"
        label={i18n.username}
        type="text"
        placeholder=""
        ariaLiveUpdateType="assertive"
        errorMessage={i18n.please_enter_a_username}
        hasError={showUserNameError}
        bind:value={userName}
    />
</div>
<div
    class="itsl-itsl-native-login-view__password"
    bind:this={passwordContainer}
    on:keydown|stopPropagation={onPasswordFieldKeyDownHandler}
    >
    <PasswordField
        id="PasswordField"
        mode="enter"
        bind:hideValue={hidePassword}
        i18n={passwordFieldLanguageTerms}
        errorMessage={i18n.password_field_Please_enter_a_password}
        hasError={showPasswordError}
        bind:value={password}
    />
</div>
<div class="itsl-native-login-view__login-button">
    <Button
        id="NativeLoginButton"
        type="primary"
        htmlType="button"
        text={loginButtonText}
        large={true}
        ariaLabel={loginButtonAriaLabelText}
        loading={isLoginInProgress}
        disabled={isLoginInProgress}
        on:click={onLoginButtonClickHandler}
    />
</div>

<script>
import TextField from "@itslearning/prometheus/assets/inputs/TextField/v1/TextField.svelte";
import PasswordField from "@itslearning/prometheus/assets/inputs/PasswordField/v1/PasswordField.svelte";
import Banner from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';
import Button from "@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte";

import { onMount, tick } from "svelte";
import { formatLanguageString } from "@itslearning/atlas/strings/format.js";
import { Keys } from "@itslearning/atlas/keyboard/keys";

export let i18n = {};
export let customNativeButtonText = "";
export let nativeLoginPostBackFunctionName = "";
export let userNameControlId = "";
export let passwordControlId = "";
export let errorMessageControlId = "";
export let errorControlForModernId = "";

let userName = "";
let password = "";
let userNameControl;
let passwordControl;
let showUserNameError = false;
let showPasswordError = false;
let loginButtonText = "";
let loginButtonAriaLabelText = "";
let isLoginInProgress = false;
let hidePassword = true;
let loginErrorText = "";

let userNameContainer;
let passwordContainer;

$: userName && (showUserNameError = false);
$: password && (showPasswordError = false);

// TODO: When changes to allow aria-describedby attribute usage for text field and password field prometheus controls are done
// those should be used on login view instead of this approach
// https://jira.sanomalearning.com/browse/ITS-87551
const setAriaDescribedByForElement = (element, connectedElementId) => {
    if (!element || !connectedElementId) {
        return;
    }

    const ariaDescribedByAttributeName = "aria-describedby";
    const ariaDescribedBy = element.getAttribute(ariaDescribedByAttributeName);

    element.setAttribute(ariaDescribedByAttributeName, `${ariaDescribedBy} ${connectedElementId}`);
};

onMount(() => {
    userNameControl = document.getElementById(userNameControlId);
    userName = userNameControl.value;

    passwordControl = document.getElementById(passwordControlId);

    setAriaDescribedByForElement(
        userNameContainer.querySelector("input[type=\"text\"]"),
        errorMessageControlId);
});

window.addEventListener(
    "pageshow",
    onPageshowHandler
);

$: passwordFieldLanguageTerms = {
    ...i18n.passwordFieldLanguageTerms,
    minNumCharacters: num => i18n.passwordFieldLanguageTerms && num
        ? formatLanguageString(i18n.passwordFieldLanguageTerms.minNumCharacters, num)
        : ""
};

$: {
    if (customNativeButtonText) {
        loginButtonText = customNativeButtonText;
        loginButtonAriaLabelText = i18n.login_With_itslearning_username_password;
    } else {
        loginButtonText = i18n.login_native_button;
    }
}

$: {
    if (passwordControl) {
        passwordControl.value = password;
    }

    if (userNameControl) {
        userNameControl.value = userName;
    }
}

function onPageshowHandler() {
    isLoginInProgress = false;

    const errorControlForModern = document.getElementById(errorControlForModernId);

    loginErrorText = errorControlForModern && errorControlForModern.value || loginErrorText;
}

function onPasswordFieldKeyDownHandler(event) {
    const { key } = event;

    if (key === Keys.ENTER && (event.target.type === "password" || event.target.type === "text" )) {
        onLoginButtonClickHandler();
    }
}

function onLoginButtonClickHandler() {
    showUserNameError = false;
    showPasswordError = false;

    if (!userName) {
        showUserNameError = true;

        return;
    }

    if (!password) {
        showPasswordError = true;

        return;
    }

    isLoginInProgress = true;
    hidePassword = true;
    window[nativeLoginPostBackFunctionName]();
}

$: {
    if (passwordContainer) {
        tick().then(() =>  setAriaDescribedByForElement(
            passwordContainer.querySelector(`input[type="${hidePassword ? "password" : "text"}"]`),
            errorMessageControlId));
    }
}
</script>
import { languagePickerStore } from '../stores.js';

export default {
    languageSelected,
    showModal,
    hideModal
};

function languageSelected({ detail: { selectedItem } }) {
    // Set the selected language to reflect the change in the components
    languagePickerStore.update(value => ({ ...value, selectedLanguageValue: selectedItem.value }));

    const url = new URL(window.location.href);

    // Reload with the LanguageId search param to update the the back end
    url.searchParams.set('LanguageId', selectedItem.id);
    window.location.href = url;
}

function showModal() {
    languagePickerStore.update(value => ({ ...value, showModal: true }));

    // Run with setTimeout to defer the find and focus until after the DOM is updated
    tryFocus('language-picker--grid-select');
}

function tryFocus(id) {
    setTimeout(() => {
        const element = document.getElementById(id);

        if (element) {
            element.focus();
        }
    }, 0);
}

function hideModal() {
    languagePickerStore.update(value => ({ ...value, showModal: false }));
    tryFocus('language-picker--modal-button');
}

<Banner
    visible={$bannerStore.bannerVisible}
    html={$bannerStore.bannerMessage}
    close={i18n.close}
/>

<script>
    import Banner from '@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte';
    import { bannerStore } from '../../stores.js';

    export let i18n = {};
</script>